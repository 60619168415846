import React, {useCallback} from "react";
import {useThree} from "@react-three/fiber";
import {button, folder, useControls} from "leva";

export function Download({sendMsg}) {
  const {gl} = useThree();
  const callback = useCallback(
    () => {
      const uri = gl.domElement.toDataURL('image/png');
      sendMsg({etype: "SNAPSHOT", value: {screen: uri}});
      const link = document.createElement('a')
      link.setAttribute('download', 'canvas.png')
      link.setAttribute(
        'href',
        uri.replace('image/png', 'image/octet-stream')
      )
      link.click()
    }, [sendMsg]
  )
  useControls({
    "Take Screenshot": button(callback, {disabled: false}),
  }, [])

  return null;
}