import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { button, buttonGroup, useControls } from "leva";
import { RenderPlane } from "../_three_components/_render_plane";
import { SocketContext } from "../_contexts/_websocket";
import { RGB_DEFAULT } from "../../_constants/_menu_defaults";
import { BBox } from "../_three_components/_primitives/_bbox";
import {
  GroupSlave,
  obj2array,
  rot2array,
  scale2array,
} from "../_three_components/_group";

export const RenderContext = createContext("Render");

export function LayerSelector({
  channel,
  _key,
  value,
  options,
  onChange,
  label,
  ...rest
}) {
  const _onChange = useCallback((value, propName, options) => {
    if (!onChange || options.initial) return;
    if (value === "delete") return onChange({ $delete: channel });
    if (value) {
      if (channel === "New") return set({ [_key]: undefined });
      onChange({ $insert: value });
    }
  }, []);
  const [control, set] = useControls(
    "Render",
    () => ({
      "Add Layer": {
        value: channel,
        options: [...options, "delete"],
        onChange: _onChange,
        // transient: false,
      },
    }),
    [channel]
  );
  return <></>;
}

// A very simple control middleware component that inserts parameters
// for specific client events.
function buttonHelper({ type, etype, ...options }, sendMsg) {
  if (type === "BUTTON") return button(() => sendMsg({ etype }), options);
  return { type, ...options };
}

export function Render({
  _key,
  sendMsg,
  hide,
  // channels: ["rgb", "depth", ]
  // layers: paramsLayers = ["rgb", "heatmap"],
  layers = [],
  // channels = ["rgb", "alpha"],
  options = ["rgb", "accumulation", "depth", "heatmap"],
  settings = {},
  children,
  ...rest
}) {
  const context = useMemo(() => {
    return { layers: new Set() };
  }, []);
  const { uplink, downlink } = useContext(SocketContext);
  const [controls, setLeva] = useControls("Render", () => ({
    renderHeight: {
      value: 768,
      min: 1,
      max: 2304,
      step: 1,
      pad: 1,
      label: "Height",
    },
    "Res Presets": buttonGroup({
      // label: "Res Presets",
      opts: {
        384: () => setLeva({ renderHeight: 384 }),
        480: () => setLeva({ renderHeight: 480 }),
        768: () => setLeva({ renderHeight: 768 }),
        960: () => setLeva({ renderHeight: 960 }),
        1536: () => setLeva({ renderHeight: 1536 }),
      },
    }),
    progressive: {
      value: -2,
      min: -3,
      max: 0,
      step: 0.5,
      label: "Progressive Scale",
    },
    Presets: buttonGroup({
      // label: "Preset",
      opts: {
        "-3": () => setLeva({ progressive: -3 }),
        "-2.5": () => setLeva({ progressive: -2.5 }),
        "-2": () => setLeva({ progressive: -2 }),
        "-1.5": () => setLeva({ progressive: -1.5 }),
        "-1": () => setLeva({ progressive: -1 }),
        "-1/2": () => setLeva({ progressive: -0.5 }),
        "/": () => setLeva({ progressive: 0 }),
      },
    }),
  }));
  const _settings = useMemo(() => {
    return Object.entries(settings || {}).reduce((acc, [key, value]) => {
      return { ...acc, [key]: buttonHelper(value, (e) => uplink?.publish(e)) };
    }, {});
  }, [settings]);

  const l = useMemo(() => {
    const keyVals = children.map((c) => [
      c.key,
      { value: layers.indexOf(c.key) >= 0, label: `[${c.key}]` },
    ]);
    const layer_settings = Object.fromEntries(keyVals);
    // console.log("layer settings", layer_settings, layers);
    return layer_settings;
  }, []);

  const layerSelect = useControls("Render.Layers", l, [layers]);
  const selectChildren = useMemo(
    () => children.filter((c) => layerSelect[c.key]),
    [layerSelect]
  );

  const renderSettings = useControls("Render.more․․․", _settings, {
    collapsed: true,
  });
  // the world position and rotations are added on the top.
  useEffect(() => {
    setTimeout(() => uplink?.publish({ etype: "CAMERA_UPDATE" }), 0);
    return uplink.addReducer("CAMERA_MOVE", (event) => {
      // const layers = context.layers;
      // console.log("context", context, "layers", layers);
      const payload = {
        ...event,
        value: {
          ...event.value,
          render: {
            ...(event.value.render || {}),
            // ideally this should be done as part of the schema.
            // progressive: Math.pow(2, progressive),
            ...controls,
            ...renderSettings,
            // cast to list
            layers: selectChildren.map((c) => c.key),
          },
        },
      };
      // console.log("payload", payload);
      return payload;
    });
  }, [selectChildren, uplink, renderSettings, controls]);

  return (
    <>
      {renderSettings.use_aabb ? (
        <GroupSlave>
          <BBox min={renderSettings.aabb_min} max={renderSettings.aabb_max} />
        </GroupSlave>
      ) : null}
      <RenderContext.Provider value={context}>
        {selectChildren}
      </RenderContext.Provider>
    </>
  );
}

export function RenderLayer({
  _key,
  sendMsg,
  hide,
  // show: defaultShow = true,
  socketURI,
  onChange: paramsOnChange,

  /** local parameters */
  title = `Render.Channel [${_key}]`,
  // these two are now included in the render parameters
  channel,
  alphaChannel,
  settings = RGB_DEFAULT,
  distance = 10,
  interpolate = false,
  opacity = 1.0,
  folderOptions = null,
  ..._
}) {
  const [rgbURI, setRGB] = useState(null);
  const [alphaURI, setAlphaMap] = useState(null);
  const { uplink, downlink } = useContext(SocketContext);
  const setting_cache = useMemo(
    () =>
      Object.entries(settings || {}).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: buttonHelper(value, (e) => uplink?.publish(e)),
        }),
        {}
      ),
    [settings]
  );

  const renderParams = useControls(title, setting_cache, folderOptions, [
    settings,
  ]);
  // the world position and rotations are added on the top.
  useLayoutEffect(() => {
    setTimeout(() => uplink?.publish({ etype: "CAMERA_UPDATE" }), 0);
    return uplink.addReducer(
      "CAMERA_MOVE",
      (event) => {
        // console.log("render layer: event", event);
        return {
          ...event,
          value: {
            ...event.value,
            render: { ...(event.value.render || {}), [_key]: renderParams },
          },
        };
      },
      channel
    );
  }, [uplink, renderParams]);

  const control = useControls(
    `${title}.more․․․`,
    {
      interpolate: interpolate,
      distance: distance,
      opacity: { value: opacity || 1, label: "Opacity", min: 0, max: 1.0 },
    },
    { label: "Options", collapsed: true },
    []
  );

  const onMessage = useCallback(
    ({ etype, data }) => {
      // console.log("RenderLayer", etype, data);
      if (etype !== "RENDER") return;

      if (channel in data) {
        // allow local rendering params over-ride.
        // console.log(renderParams.channel, channel);
        let uri = data[renderParams.channel || channel];
        if (!uri) return;
        if (!uri?.startsWith || !uri.startsWith("data:image/")) {
          const blob = new Blob([uri], { type: "image" });
          uri = blob;
        }
        setRGB(uri);
      }
      if (alphaChannel in data) {
        // allow local rendering params over-ride.
        // console.log(renderParams.alphaChannel, alphaChannel);
        let uri = data[renderParams.alphaChannel || alphaChannel];
        if (!uri) return;
        if (!uri?.startsWith || !uri?.startsWith("data:image/")) {
          const blob = new Blob([uri], { type: "image" });
          uri = blob;
        }
        setAlphaMap(uri);
      }
    },
    [renderParams.useAlpha]
  );
  useLayoutEffect(() => downlink.subscribe("RENDER", onMessage), [downlink]);

  return (
    <>
      {renderParams.use_aabb ? (
        <GroupSlave>
          <group
            position={obj2array(renderParams.position)}
            rotation={rot2array(renderParams.rotation)}
            scale={scale2array(renderParams.scale)}
          >
            <BBox min={renderParams.aabb_min} max={renderParams.aabb_max} />
          </group>
        </GroupSlave>
      ) : null}
      {rgbURI ? (
        <RenderPlane
          src={rgbURI}
          alphaSrc={renderParams.useAlpha && alphaURI}
          // todo: add displacementMap, and other texture maps.
          distanceToCamera={control.distance}
          interpolate={control.interpolate}
          // useAlpha={renderParams.useAlpha}
          opacity={control.opacity}
        />
      ) : null}
    </>
  );
}
