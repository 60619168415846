import React, { useMemo } from "react"; // eslint-disable-next-line react/no-unknown-property
import { half2float } from "./_half2float";

export function PointCloud({
  tag,
  id,
  src,
  buff,
  hide,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  vertices,
  colors,
  size = 0.01,
  color,
  ...rest
}) {
  const geometry = useMemo(() => {
    return {
      vertices: half2float(vertices),
      colors: colors && Float32Array.from(colors, (octet) => octet / 0xff),
    };
  }, [vertices, colors]);

  return (
    <points
      // ref={ref}
      position={position}
      rotation={rotation}
      castShadow
      receiveShadow
      {...rest}
    >
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={geometry.vertices}
          count={geometry.vertices.length / 3}
          itemSize={3}
        />
        {colors && (
          <bufferAttribute
            attach="attributes-color"
            array={geometry.colors}
            count={geometry.colors.length / 3}
            itemSize={3}
          />
        )}
      </bufferGeometry>
      <pointsMaterial
        attach="material"
        //only use vertex colors if it is provided.
        vertexColors
        color={color}
        size={size}
      />
    </points>
  );
}
