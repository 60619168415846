import React, { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  DataTexture,
  LinearFilter,
  NearestFilter,
  NoColorSpace,
  Texture, TextureLoader,
  Vector3,
} from "three";
import { Plane } from "@react-three/drei";

function interpolateTexture(texture, interpolate) {
  if (!texture) return;
  if (interpolate) {
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
  } else {
    texture.minFilter = NearestFilter;
    texture.magFilter = NearestFilter;
  }
}

export function RenderPlane({
  src = null,
  alphaSrc = null,
  distanceToCamera = 10,
  interpolate = false,
  // useAlpha = null,
  opacity = 1.0,
}) {
  const planeRef = useRef();
  const matRef = useRef();

  const { camera } = useThree();

  useFrame(({}) => {
    if (!planeRef.current) return;
    // if (!src || !depthSrc) return;
    if (!src) return;
    const plane = planeRef.current;
    // note: only works with perspective camera
    let h;
    let w;
    if (camera.type === "PerspectiveCamera") {
      h = 2 * Math.tan((camera.fov / 360) * Math.PI) * distanceToCamera;
      w = camera.aspect * h;
    } else if (camera.type === "OrthographicCamera") {
      // handle Orthographic Camera
      h = (camera.top - camera.bottom) / camera.zoom;
      w = (camera.right - camera.left) / camera.zoom;
    } else {
      console.warn("Unsupported camera type", camera.type);
    }
    plane.scale.set(w, h, 1);
    const dirVec = new Vector3(0, 0, -1)
      .applyEuler(camera.rotation)
      .normalize();
    plane.position
      .copy(camera.position)
      .addScaledVector(dirVec, distanceToCamera);
    plane.lookAt(camera.position);
  });

  const [rgbTexture, setRGB] = useState(null);
  const [alphaTexture, setAlpha] = useState(null);

  useEffect(() => {
    if (!src) {
      setRGB(null);
      return;
    }
    if (typeof src === "string") {
      const loader = new TextureLoader();
      loader.load(src, setRGB);
      return;
    }
    const texture = new Texture();
    createImageBitmap(src, {imageOrientation: "flipY"}).then((imageBitmap) => {
      texture.image = imageBitmap;
      texture.needsUpdate = true;
      matRef.current.needsUpdate = true
      setRGB(texture);
    });
  }, [src]);

  useEffect(() => {
    if (!alphaSrc) {
      setAlpha(null);
      return;
    }
    if (typeof src === "string") {
      const loader = new TextureLoader(src);
      loader.load(alphaSrc, setRGB);
      return;
    }
    const texture = new Texture();
    createImageBitmap(alphaSrc, {imageOrientation: "flipY"}).then((imageBitmap) => {
      texture.image = imageBitmap;
      texture.needsUpdate = true;
      matRef.current.needsUpdate = true
      setAlpha(texture);
    });
  }, [alphaSrc]);

  useEffect(() => {
    interpolateTexture(rgbTexture, interpolate);
    interpolateTexture(alphaTexture, interpolate);
  }, [rgbTexture, alphaTexture, interpolate]);

  return (
    <Plane
      ref={planeRef}
      args={[1, 1, rgbTexture?.image?.width, rgbTexture?.image?.height]}
      scale={[camera?.aspect || 1, 1, 1]}
      frustrumCulled={false}
    >
      <meshBasicMaterial
        ref={matRef}
        attach="material"
        map={rgbTexture}
        alphaMap={alphaTexture}
        transparent
        opacity={opacity}
      />
    </Plane>
  );
}
