import React, { useMemo } from "react";
// import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Remark } from "react-remark";

export function Markdown({ sendMsg, _key: key, children, style, ...props }) {
  const { markdown, components } = useMemo(
    () => ({
      markdown: typeof children === "string" ? children : children.join(" "),
      components: {
        p: ({ children, style: _style, ..._props }) => {
          return (
            <p style={{ ...style, ..._style }} {..._props}>
              {children}
            </p>
          );
        },
      },
    }),
    [children, style]
  );
  console.log(markdown);
  return (
    <Remark
      rehypePlugins={[rehypeRaw]}
      rehypeReactOptions={components}
      remarkToRehypeOptions={{ allowDangerousHtml: true }}
      {...props}
    >
      {markdown}
    </Remark>
  );
}
