import React, { useEffect, useState } from "react";
import { Center, PivotControls, Sphere } from "@react-three/drei";

export function PcdView({ data, _ref, size = 0.0015, hide = null, ...rest }) {
  //rest includes translation and rotation
  if (!data || hide) return null;
  return (
    <points ref={_ref} geometry="sphere" {...rest}>
      <Geometry attach="geometry" {...data.geometry} />
      <pointsMaterial
        attach="material"
        vertexColors
        size={size}
        sizeAttenuation={true}
        transparent={true}
        alphaTest={1}
        opacity={1}
      />
    </points>
  );
}

export function ObjView({ data, _ref, hide = null, ...rest }) {
  if (!data || hide) return null;
  // todo: offer wireframe and color-override options.
  return (
    <primitive
      ref={_ref}
      receiveShadow={true}
      castShadow={true}
      object={data}
      {...rest}
    />
  );
}

export function PlyView({
  data,
  _ref,
  size = 0.005,
  color = null,
  hide = null,
  ...rest
}) {
  // computing the normals on unordered pointcloud is bad.
  if (!data || hide) return null;

  if (!data.attributes.normal) console.log("data.attributes.normal is missing");
  if (!data.index)
    console.log(
      "data.index is missing. Won't be able to compute surface normals."
    );
  if (data && !data.attributes.normal && data.index) {
    // console.log('computed normals for data. ')
    data.computeVertexNormals();
  }
  if (!!data.attributes.normal) {
    return (
      <mesh
        ref={_ref}
        geometry={data}
        castShadow={true}
        recieveShadow={true}
        {...rest}
      >
        <meshStandardMaterial vertexColors />
      </mesh>
    );
  } else if (!!color) {
    return (
      <points ref={_ref} {...rest}>
        <bufferGeometry {...data} />
        <pointsMaterial
          size={size}
          vertexColors
          color={color}
          sizeAttenuation
        />
      </points>
    );
  } else {
    return (
      <points geometry={data} {...rest}>
        <pointsMaterial
          attach="material"
          vertexColors
          size={size}
          sizeAttenuation={true}
          opacity={1}
        />
      </points>
    );
  }
}

// <pointsMaterial attach="material" vertexColors size={size} sizeAttenuation={true} opacity={1}/>
// <points>
//   <Geometry attach="geometry" {...data}/>
//   <pointsMaterial sizeAttenuation attach="material" color={color} depthWrite={false} size={size}/>
// </points>

// GLB is a binary container format of GLTF.
export function GltfView({ data, _ref, ...rest }) {
  return <primitive ref={_ref} object={data.scene} {...rest} />;
}

export function UrdfView({ robot, _ref, jointValues = {}, ...rest }) {
  // const robot = useRef();
  // robot.current = data;
  useEffect(
    function () {
      if (jointValues) robot?.setJointValues(jointValues);
    },
    [robot, jointValues]
  );
  return <primitive ref={_ref} object={robot} {...rest} />;
}

export function Gripper({
  _ref,
  color = null,
  pinchWidth = 0.04,
  skeleton = false,
  axes = false,
  // showCoordnates = True,
  showOrigin = true,
  hide = null,
  ...rest
}) {
  if (hide) return null;
  if (skeleton) {
    return (
      <SkeletalGripper
        _ref={_ref}
        color={color}
        pinchWidth={pinchWidth}
        {...rest}
      />
    );
  }
  return (
    <group ref={_ref} {...rest}>
      {axes && <axesHelper args={[0.1]} />}
      <mesh
        position={[0, 0.07, 0]}
        rotation={[0, 0, Math.PI / 2]}
        scale={[1, 1, 1]}
      >
        <cylinderGeometry attach="geometry" args={[0.015, 0.015, 0.12, 32]} />
        <meshBasicMaterial attach="material" color={color || "#cecece"} />
      </mesh>
      <mesh position={[-pinchWidth - 0.005, 0.0, 0.0]} scale={1}>
        <boxGeometry attach="geometry" args={[0.01, 0.02, 0.025, 32]} />
        <meshBasicMaterial attach="material" color={color || "#23aaff"} />
      </mesh>
      <mesh position={[-pinchWidth - 0.005, 0.0275 + 0.01, 0.0]} scale={1}>
        <boxGeometry attach="geometry" args={[0.01, 0.055, 0.025, 32]} />
        <meshBasicMaterial attach="material" color={color || "#8ed6ff"} />
      </mesh>
      <mesh position={[pinchWidth + 0.005, 0.0, 0.0]} scale={1}>
        <boxGeometry attach="geometry" args={[0.01, 0.02, 0.025, 32]} />
        <meshBasicMaterial attach="material" color={color || "#ff5656"} />
      </mesh>
      <mesh position={[pinchWidth + 0.005, 0.0275 + 0.01, 0.0]} scale={1}>
        <boxGeometry attach="geometry" args={[0.01, 0.055, 0.025, 32]} />
        <meshBasicMaterial attach="material" color={color || "#ff9595"} />
      </mesh>
      <mesh
        position={[0, 0.075 + 0.025, 0]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
      >
        <cylinderGeometry attach="geometry" args={[0.0125, 0.0125, 0.05, 32]} />
        <meshBasicMaterial attach="material" color={color || "#5b5b5b"} />
      </mesh>
      {/*add a sphere to show the origin*/}
      {showOrigin ? (
        <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
          <sphereGeometry attach="geometry" args={[0.0075, 32, 32]} />
          <meshBasicMaterial attach="material" color="green" />
        </mesh>
      ) : null}
    </group>
  );
}

export function SkeletalGripper({
  _ref,
  color = null,
  pinchWidth = 0.07,
  opacity = 0.9,
  children,
  hide = null,
  ...rest
}) {
  if (hide) return null;
  return (
    <group scale={1} {...rest} ref={_ref}>
      <mesh
        position={[0, 0.07, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
      >
        <cylinderGeometry
          attach="geometry"
          args={[0.005, 0.005, pinchWidth * 2, 32]}
        />
        <meshBasicMaterial
          color={color || "#ffffff"}
          transparent={true}
          opacity={opacity}
        />
      </mesh>
      <mesh position={[0, 0.02, pinchWidth]} scale={1}>
        <cylinderGeometry attach="geometry" args={[0.005, 0.005, 0.1, 32]} />
        <meshBasicMaterial
          attach="material"
          color={color || "#23aaff"}
          transparent={true}
          opacity={opacity}
        />
      </mesh>
      <mesh position={[0, 0.02, -pinchWidth]} scale={1}>
        <cylinderGeometry attach="geometry" args={[0.005, 0.005, 0.1, 32]} />
        <meshBasicMaterial
          attach="material"
          color={color || "#ff5656"}
          transparent={true}
          opacity={opacity}
        />
      </mesh>
      <mesh position={[0, 0.09, 0]} rotation={[0, 0, 0]} scale={[0.8, 1, 1]}>
        <cylinderGeometry attach="geometry" args={[0.01, 0.01, 0.06, 32]} />
        <meshBasicMaterial
          attach="material"
          color={color || "gray"}
          transparent={true}
          opacity={opacity}
        />
      </mesh>
      <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <sphereGeometry attach="geometry" args={[0.01, 32, 32]} />
        <meshBasicMaterial
          attach="material"
          color={color || "green"}
          transparent={true}
          opacity={opacity}
        />
      </mesh>
    </group>
  );
}

export function Marker({ anchor, rotation, radius, hoverScale = 3 }) {
  const [show, setShow] = useState(false);
  const [scale, setScale] = useState(1);

  //use useEvent after upgrading to next react version
  const onClick = (e) => {
    setShow(!show);
    e.stopPropagation();
  };
  const pointerOver = () => setScale(hoverScale);
  const pointerOut = () => setScale(1);

  if (!show)
    return (
      <Sphere
        args={[radius * scale]}
        position={anchor}
        onClick={onClick}
        onPointerOver={pointerOver}
        onPointerOut={pointerOut}
      >
        <meshPhongMaterial color="blue" opacity={1} transparent />
      </Sphere>
    );

  return (
    <PivotControls
      depthTest={false}
      anchor={anchor}
      rotation={rotation}
      scale={radius * scale}
      visible={show}
      disableAxes={!show}
      disableSliders={!show}
      disableRotations={!show}
    >
      <Center center position={anchor}>
        <Sphere
          args={[radius * scale]}
          onClick={onClick}
          onPointerOver={pointerOver}
          onPointerOut={pointerOut}
        >
          <meshPhongMaterial color="blue" opacity={1} transparent />
        </Sphere>
      </Center>
    </PivotControls>
  );
}
