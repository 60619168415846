import React, { useContext } from "react";
import { comp_list } from "./_components";
import { SocketContext } from "./_contexts/_websocket";

export function Hydrate({
  _key: key,
  tag: Tag = "div",
  children,
  className,
  ...rest
}) {
  const Component = comp_list[Tag] || Tag;
  const { sendMsg, uplink } = useContext(SocketContext);

  const hydratedChildren = (children || []).map((child) => {
    if (typeof child === "string") return child;
    const { key, ..._child } = child;
    return <Hydrate key={key} _key={key} sendMsg={sendMsg} {..._child} />;
  });

  if (typeof Component === "string") {
    return (
      <Component key={key} className={className} {...rest}>
        {hydratedChildren}
      </Component>
    );
  } else {
    return (
      <Component
        key={key}
        className={className}
        _key={key}
        sendMsg={sendMsg}
        {...rest}
      >
        {hydratedChildren}
      </Component>
    );
  }
}
