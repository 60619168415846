import React, { useContext, useEffect, useMemo } from "react";
import { useControls } from "leva";
import { group, Vector3 } from "three";
import { SocketContext } from "../_contexts/_websocket";
import { parse } from "query-string";
import { document } from "browser-monads";
import { useSceneStore } from "../../_store";

// todo: need to handle the order of the rotation.
export function deg2rad(rotation) {
  return {
    x: (rotation.x * Math.PI) / 180,
    y: (rotation.y * Math.PI) / 180,
    z: (rotation.z * Math.PI) / 180,
    order: rotation.order || "XYZ",
  };
}

export const obj2array = ({ x, y, z }) => [x, y, z];
export const rot2array = (rotation) => obj2array(deg2rad(rotation));

export const scale2array = (scale) => {
  if (typeof scale === "number") return [scale, scale, scale];
  return obj2array(scale);
}


export const SceneGroup = ({
  // sendMsg,
  levaPrefix = "Scene",
  position: paramPosition,
  rotation: paramRotation,
  scale: paramScale,
  children,
  ..._
}) => {
  const queries = useMemo(() => {
    const q = parse(document.location.search);

    let rotation;
    let position;
    let scale;

    if (q.rotation) {
      const [x, y, z] = q.rotation
        ?.split(",")
        .filter((u) => u && u === u)
        .map(parseFloat);
      rotation = { x, y, z };
    }
    if (q.position) {
      const [x, y, z] = q.position
        ?.split(",")
        .filter((u) => u && u === u)
        .map(parseFloat);
      position = { x, y, z };
    }
    if (q.scale) {
      scale = parseFloat(q.scale);
    }

    return { rotation, position, scale };
  }, []);

  const { position, rotation, scale } = useControls(
    levaPrefix,
    {
      rotation: {
        value: paramRotation || queries.rotation || { x: 0, y: 0, z: 0 },
        order: 1,
        lock: true,
      },
      position: {
        value: paramPosition || queries.position || { x: 0, y: 0, z: 0 },
        order: -1,
        lock: true,
      },
      scale: {
        value: paramScale || queries.scale || 1,
        min: 0.0001,
        step: 0.01,
        label: "Scale",
        order: 2,
        pad: 4,
      },
    },
    { collapsed: true }
  );
  const { uplink } = useContext(SocketContext);
  const sceneStore = useSceneStore();

  // here we register a reducer for "CAMERA_MOVE" events. This will trigger
  // a camera update event in the _camera.js component.
  useEffect(() => {
    // emit the event in a timeout, so it happens after the addReducer synchronous call.
    setTimeout(() => uplink?.publish({ etype: "CAMERA_UPDATE" }), 0);

    // update the scene store.
    sceneStore.update({ position, rotation, scale });

    return uplink.addReducer("CAMERA_MOVE", (event) => {
      return {
        ...event,
        value: {
          ...event.value,
          world: {
            ...event.value?.world,
            position,
            rotation, //: deg2rad(rotation),
            scale,
          },
        },
      };
    });
  }, [uplink, position, rotation, scale]);
  return (
    <group
      position={obj2array(position) || [0, 0, 0]}
      rotation={rot2array(rotation) || [0, 0, 0]}
      scale={scale2array(scale || 1.0)}
    >
      {children}
    </group>
  );
};
export const GroupSlave = ({
  // sendMsg,
  // position,
  // rotation,
  // scale,
  children,
  ...rest
}) => {
  const { position, rotation, scale } = useSceneStore();
  return (
    <group
      position={obj2array(position) || [0, 0, 0]}
      rotation={rot2array(rotation) || [0, 0, 0]}
      scale={scale2array(scale || 1.0)}
    >
      {children}
    </group>
  );
};
