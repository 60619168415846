import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { SqueezeRayGrab } from "./_utils";
import { PivotControls } from "@react-three/drei";
import { useXR } from "@react-three/xr";
import { invalidate } from "@react-three/fiber";

export const HandleBox = forwardRef(({ size, children, ...rest }, ref) => {
  return (
    <mesh ref={ref} {...rest}>
      <boxGeometry args={size} />
      <meshPhongMaterial color={0xfffff7} />
      {children}
    </mesh>
  );
});

export function Pivot({
  children,
  _key,
  sendMsg = () => null,
  anchor,
  offset,
  annotations,
  scale = 0.4,
  lineWidth = 1.0,
  matrix,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  ...rest
}) {
  const [state, setState] = useState({});
  const ref = useRef();

  const cache = useMemo(() => ({
    position: new THREE.Vector3(...position),
    rotation: new THREE.Euler(...rotation),
    quaternion: new THREE.Quaternion(),
    scale: new THREE.Vector3(),
  }));

  useEffect(() => {
    if (!ref.current) return;
    const pivot = ref.current;
    if (matrix) {
      pivot.matrix.fromArray(matrix);
      pivot.matrix.decompose(pivot.position, pivot.quaternion, pivot.scale);
      pivot.rotation.setFromQuaternion(pivot.quaternion);
    } else {
      pivot.position.fromArray(position);
      pivot.rotation.fromArray(rotation);
      pivot.updateMatrix();
      invalidate();
    }
  }, [ref.current]);

  // function onDrag (l: THREE.Matrix4, deltaL: THREE.Matrix4, w: THREE.Matrix4, deltaW: THREE.Matrix4) {}
  function onDrag(local, deltaL, world, deltaW) {
    local.decompose(cache.position, cache.quaternion, cache.scale);
    cache.rotation.setFromQuaternion(cache.quaternion);
    setState({
      local: local.toArray(),
      world: world.toArray(),
      position: cache.position.toArray(),
      rotation: cache.rotation.toArray(),
      quaternion: cache.quaternion.toArray(),
    });
  }

  function onDragEnd() {
    sendMsg({
      etype: "OBJECT_MOVE",
      key: _key,
      value: state,
    });
  }

  return (
    <PivotControls
      ref={ref}
      anchor={anchor}
      annotations={annotations}
      offset={offset}
      scale={scale}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      lineWidth={lineWidth}
      {...rest}
    >
      {children}
    </PivotControls>
  );
}

// export function MovableGripper({_key, sendMsg, color = null, pinchWidth = 1, ...rest}) {
//   const cloud_ref = useRef();
//   const [pinchW, setPinchW] = useState(pinchWidth)
//
//   // make a ref for the sSkeletalGripper
//   const gripper_ref = useRef()
//   // use useMemo to create a skeleton gripper only once
//   const gripperSkeleton = useMemo(() => <SkeletalGripper _ref={gripper_ref}/>, [])
//
//   const {position, rotation, handleOffset} = rest
//
//   const cache.position = useMemo(() => new THREE.Vector3(), [])
//   const cache.quaternion = useMemo(() => new THREE.Quaternion(), [])
//   const cache.rotation = useMemo(() => new THREE.Euler(), [])
//   const cache.scale = useMemo(() => new THREE.Vector3(), [])
//   const onSelect = () => {
//     cloud_ref.current?.matrixWorld.decompose(cache.position, cache.quaternion, cache.scale)
//     // convert cache.rotation quaternion to euler
//     cache.rotation.setFrocache.quaternion(cache.quaternion)
//     sendMsg({
//       etype: 'SET_GRIPPER',
//       key: _key,
//       value: {
//         rotation: cache.rotation.toArray().slice(0, 3),
//         position: cache.position.toArray()
//       }
//     })
//     // also set the skeletal gripper
//     gripper_ref.current?.rotation.fromArray(cache.rotation.toArray())
//     gripper_ref.current?.position.fromArray(cache.position.toArray())
//   }
//
//   return (<>
//         <SqueezeRayGrab onSelect={onSelect} bigChildren={
//           <Gripper _ref={cloud_ref} color={color} pinchWidth={pinchW} {...rest}/>
//         }>
//           <HandleBox size={[0.1, 0.1, 0.1]} rotation={rotation} position={addThree(position, handleOffset)}/>
//         </SqueezeRayGrab>
//         {gripperSkeleton}
//       </>
//   )
// }

function addThree(a = [0, 0, 0], b = [0, 0, 0]) {
  return [a[0] + b[0], a[1] + b[1], a[2] + b[2]];
}

export function PivotXR({
  _key,
  sendMsg = () => null,
  offset,
  scale,
  children = [],
  position,
  rotation,
}) {
  // const cloud_ref = useRef(children.length && children[0], children);
  const ref = useRef();

  // make memo for position and rotation
  const cache = useMemo(() => ({
    position: new THREE.Vector3(),
    rotation: new THREE.Euler(),
    quaternion: new THREE.Quaternion(),
    scale: new THREE.Vector3(),
  }));

  const onSqueezeEnd = () => {
    const world = ref.current?.matrixWorld;
    world.decompose(cache.position, cache.quaternion, cache.scale);
    // convert cache.rotation quaternion to euler
    cache.rotation.setFromQuaternion(cache.quaternion);
    sendMsg({
      etype: "OBJECT_MOVE",
      key: _key,
      value: {
        world: world.toArray(),
        position: cache.position.toArray(),
        rotation: cache.rotation.toArray(),
        quaternion: cache.quaternion.toArray(),
      },
    });
  };

  return (
    <SqueezeRayGrab onSqueezeEnd={onSqueezeEnd} bigChildren={children}>
      <HandleBox
        ref={ref}
        size={[scale, scale, scale]}
        rotation={rotation}
        position={addThree(position, offset)}
      />
    </SqueezeRayGrab>
  );
}

export function Movable({
  children,
  _key,
  anchor = null,
  offset,
  annotations,
  scale = 0.4,
  lineWidth = 0.5,
  handleOffset = [0, 0, 0],
  hide = null,
  ...props
}) {
  // hide movable leads to pass-through
  if (hide) {
    return <>{children}</>;
  }
  const { isPresenting } = useXR();
  if (isPresenting) {
    return (
      <PivotXR
        key={_key}
        _key={_key}
        scale={scale * 0.1}
        offset={handleOffset}
        {...props}
      >
        {children}
      </PivotXR>
    );
  } else
    return (
      <Pivot
        key={_key}
        _key={_key}
        anchor={anchor}
        offset={offset}
        annotations={annotations}
        scale={scale}
        lineWidth={lineWidth}
        {...props}
      >
        {children}
      </Pivot>
    );
}
