import React, {useCallback, useContext, useMemo, useRef} from "react";
import {Canvas, extend} from "@react-three/fiber";
import {Controllers, Hands, VRButton, XR} from "@react-three/xr";
import {GizmoHelper, GizmoViewport} from "@react-three/drei";
import {SSAOPass, UnrealBloomPass} from "three-stdlib";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import {BufferGeometry, Mesh} from "three";
import {acceleratedRaycast, computeBoundsTree, disposeBoundsTree,} from "three-mesh-bvh";
import {OrbitCamera} from "./_camera";
import {DepthMaterial} from "./_pseudo3D/_depthMaterial";
import {PerspectivesDepthMaterial} from "./_pseudo3D/_perspectivesDepthMaterial";
import {Download} from "./_download";
import {Perf} from "r3f-perf";
import {FileDrop} from "../_file_drop";
import {Grid} from "./_grid";
import {PointerControl} from "./_controls/_pointer";
import {SceneGroup} from "./_group";
import {SocketContext} from "../_contexts/_websocket";
import {Color} from "./_color";
import {parse} from "query-string";
import {document} from "browser-monads";

// question: what does this do? - Ge
Mesh.prototype.raycast = acceleratedRaycast;
BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTreee = disposeBoundsTree;

extend({
  SSAOPass,
  UnrealBloomPass,
  TextGeometry,
  DepthMaterial,
  PerspectivesDepthMaterial,
});

export default function ThreeScene({
  _key: key,
  canvasRef: _canvasRef,
  markers = [],
  // sendMsg = null,
  // background = "#505767",
  background = "#151822",
  backgroundChildren = null,
  style = null,
  className = null,
  position = null,
  rotation = null,
  scale = null,
  // sceneBox = true,
  // sceneBoxMin,
  // sceneBoxMax,
  children,
  // these are not transformed.
  rawChildren,
  htmlChildren,
  ...rest
}) {
  const canvasRef = _canvasRef || useRef();
  const { sendMsg, uplink } = useContext(SocketContext);
  const queries = useMemo(() => parse(document.location.search), []);

  const onCameraMove = useCallback(
    (camera) => {
      uplink?.publish({
        etype: "CAMERA_MOVE",
        key: "defaultCamera",
        value: {
          camera: {
            ...camera,
            height: canvasRef.current?.clientHeight,
            width: canvasRef.current?.clientWidth,
          },
        },
      });
    },
    [sendMsg, uplink]
  );

  const divStyle = useMemo(
    () => ({
      overflow: "hidden",
      ...(style || {
        height: "300px",
        width: "400px",
        border: "1px solid black",
        margin: "5px 5px 5px 5px",
      }),
    }),
    [style]
  );

  // style="width: 80%; height: 100%; position: absolute; z-index: 10;"
  return (
    <>
      <div style={divStyle} className={className}>
        <VRButton />
        <Canvas
          ref={canvasRef}
          shadows
          // preserve buffer needed for download
          gl={{ antialias: true, preserveDrawingBuffer: true }}
          frameloop="demand"
          // why set it to 1: https://stackoverflow.com/a/32936969/1560241
          tabIndex={1}
        >
          <Color />
          <XR>
            {queries.debug || queries.perf ? (
              <Perf position="top-left" />
            ) : null}
            <FileDrop />
            <Download sendMsg={sendMsg} />
            <Hands />
            <Controllers />
            <PointerControl
              parent={canvasRef}
              parentKey={key}
              sendMsg={sendMsg}
            />
            <Grid />
            {backgroundChildren}
            <SceneGroup>{children}</SceneGroup>
            {rawChildren}
            <OrbitCamera
              parent={canvasRef}
              onChange={onCameraMove}
              panSpeed={1}
            />
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
              <GizmoViewport
                axisColors={["#9d4b4b", "#2f7f4f", "#3b5b9d"]}
                labelColor="white"
              />
            </GizmoHelper>
          </XR>
        </Canvas>
        {/*<Leva*/}
        {/*  isRoot*/}
        {/*  left={10}*/}
        {/*  collapsed={queries.collapseMenu === "true"}*/}
        {/*  style={{ zIndex: 10000000 }}*/}
        {/*/>*/}
      </div>
      {htmlChildren}
    </>
  );
}
