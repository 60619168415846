import React, {useEffect, useState} from "react";
import {useControls} from "leva";
import {pluginFile} from "../../../leva-file-picker"; // import {Cache} from "three";
import {Glb, Obj, Pcd, Ply, Urdf} from "./_three_components/_data_loaders"; // export function Hydrate({ _key: key, tag: Tag = "div", children, sendMsg, className, ...rest }) {

// export function Hydrate({ _key: key, tag: Tag = "div", children, sendMsg, className, ...rest })
export function FileDrop({ children, sendMsg, className }) {
  const [buffer, setBuffer] = useState(null);
  const { File: file } = useControls("Upload", {
    File: pluginFile(),
  });

  useEffect(() => {
    if (!file) return;
    (async () => {
      const buff = await file.arrayBuffer();
      setBuffer(buff);
    })();
  }, [file]);

  if (!file) return <mesh />;
  // Cache.files[file.path] = file;
  if (file.path.endsWith(".ply")) {
    return <Ply buff={buffer} />;
  } else if (file.path.endsWith(".pcb")) {
    return <Pcd buff={buffer} />;
  } else if (file.path.endsWith(".obj")) {
    return <Obj buff={buffer} />;
  } else if (file.path.endsWith(".glb")) {
    return <Glb buff={buffer} />;
  } else if (file.path.endsWith(".urdf")) {
    return <Urdf buff={buffer} />;
  } else {
    return <mesh />;
  }
}
