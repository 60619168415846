import React, {useEffect, useState} from 'react'
import {GltfView, ObjView, PcdView, PlyView, UrdfView} from "./_components";
import {GLTFLoader, OBJLoader, PCDLoader, PLYLoader} from "three-stdlib";
import URDFLoader from "urdf-loader"; // todo: pass reference

// todo: pass reference
// todo: change src to src

export function Obj({ tag, id, src, text, buff, hide, encoding="ascii", ...rest }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!data && hide) return;
    const loader = new OBJLoader();
    if (buff) text = (new TextDecoder(encoding)).decode(buff);
    if (text) setData(loader.parse(text));
    else loader.load(src, setData);
  }, [src, hide]);
  if (!data) return null;
  return <ObjView data={data} hide={hide} {...rest} />;
}

export function Pcd({ tag, id, src, text, buff, hide, encoding="ascii", ...rest }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!data && hide) return;
    const loader = new PCDLoader();
    if (buff) text = (new TextDecoder(encoding)).decode(buff);
    if (text) setData(loader.parse(text));
    else loader.load(src, setData);
  }, [src, hide]);
  if (!data) return null;
  return <PcdView data={data} hide={hide} {...rest} />;
}

export function Ply({ tag, id, src, text, buff, hide, encoding="ascii", ...rest }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!data && hide) return;
    const loader = new PLYLoader();
    if (buff) text = (new TextDecoder(encoding)).decode(buff);
    if (text) setData(loader.parse(text));
    else loader.load(src, setData);
  }, [src, hide]);
  if (!data) return null;
  return <PlyView data={data} hide={hide} {...rest} />;
}

export function Glb({ tag, id, src, text, buff, hide, encoding="ascii", ...rest }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!data && hide) return;
    const loader = new GLTFLoader();
    if (buff) text = (new TextDecoder(encoding)).decode(buff);
    if (text) setData(loader.parse(text));
    else loader.load(src, setData);
  }, [src, hide]);
  if (!data) return null;
  return <GltfView data={data} hide={hide} {...rest} />;
}

export function Urdf({ tag, id, src, text, buff, hide, encoding="ascii", jointValues, ...rest }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!data && hide) return;
    const loader = new URDFLoader();
    if (buff) text = (new TextDecoder(encoding)).decode(buff);
    if (text) setData(loader.parse(text));
    else loader.load(src, setData);
  }, [src, hide]);
  if (!data) return null;
  return (
    <UrdfView robot={data} jointValues={jointValues} hide={hide} {...rest} />
  );
}