import { useLoader } from "@react-three/fiber";
import {
  DataTexture,
  NoColorSpace,
  TangentSpaceNormalMap,
  TextureLoader,
} from "three";
import React, { useEffect, useMemo } from "react";
import { height2normal } from "./_normal_map_utils";
import { useControls } from "leva";

export function SimpleMaterial({ _key, displacementMap, normalMap, ...rest }) {
  const dM = useLoader(TextureLoader, displacementMap);
  const nM = useLoader(TextureLoader, normalMap);

  return (
    <meshPhongMaterial
      attach="material"
      displacementMap={dM}
      normalMap={nM}
      normalMap-colorSpace={NoColorSpace}
      {...rest}
    />
  );
}

const isLoaded = (image) => image?.complete && image.naturalHeight !== 0;

export function HeightMaterial({
  _key,
  type, // One of 'basic', 'lambert', 'phong', 'standard'
  displacementMap,
  normalMap,
  displacementScale = 1,
  normalScale = [1, 1],
  ...rest
}) {
  const MType = "mesh" + type.charAt(0).toUpperCase() + type.slice(1) + "Material";

  const displacementTexture = useLoader(TextureLoader, displacementMap || []);
  if (displacementTexture) displacementTexture.colorSpace = NoColorSpace;

  const width = displacementTexture?.image?.width;
  const height = displacementTexture?.image?.height;

  const loadedNormal = useLoader(TextureLoader, normalMap || []);
  if (loadedNormal) loadedNormal.colorSpace = NoColorSpace;

  const normalData = useMemo(() => {
    const m = new Uint8ClampedArray(4 * width * height);
    m.fill(255);
    return m;
  }, [width, height]);

  const computedNormal = useMemo(() => {
    // allow using normalMap === false to turn off the normal calculation
    if (normalMap || normalMap === false) return null;
    const texture = new DataTexture(normalData, width, height);
    // flip the image when sending to GPU.
    texture.flipY = true;
    texture.colorSpace = NoColorSpace;
    return texture;
  }, [normalMap, width, height]);

  useEffect(() => {
    if (!!normalMap || normalMap === false) return;
    if (!isLoaded(displacementTexture.image)) return;

    height2normal(displacementTexture.image, normalData, computedNormal.flipY);
    if (!computedNormal || !computedNormal.image) return;
    computedNormal.image = { data: normalData, width, height };
    computedNormal.colorSpace = NoColorSpace;
    computedNormal.needsUpdate = true;
  }, [normalMap, displacementTexture?.image, width, height]);

  // prettier-ignore
  const folderName = `${_key} Height Map`;
  const controls = useControls(
    folderName,
    {
      displacementScale: {
        value: displacementScale,
        render: (get) => displacementMap,
      },
      normalScale: {
        value: normalScale,
        render: (get) => displacementMap && normalMap !== false,
      },
    },
    [displacementMap, displacementScale, normalMap, normalScale]
  );

  const props = {};
  if (displacementMap) {
    props.displacementMap = displacementTexture;
    props["displacementMap-colorSpace"] = NoColorSpace;
  }
  if (normalMap) props.normalMap = loadedNormal;
  else if (displacementMap) props.normalMap = computedNormal;
  if (props.normalMap) {
    props.normalMapType = TangentSpaceNormalMap;
    props["normalMap-colorSpace"] = NoColorSpace;
  }

  return (
    <MType
      attach="material"
      {...props}
      {...controls}
      {...rest}
    />
  );
}
