import { _colormaps } from "./_colormaps";

const rgb = {};

const LANGUAGE_DEFAULT = {
  type: "FOLDER",
  schema: {
    heatmap: {
      value: null,
      default: null,
      options: [null, "features"],
      label: "Heat Map",
    },
    query: { value: "", label: "Text Query" },
    negativeQueries: { value: "", rows: 2, label: "Negatives" },
    showMask: { value: true, label: "Show Mask" },
    heatmapColormap: {
      value: null,
      label: "Color Map",
      options: _colormaps,
    },
    temperature: {
      value: 0.1,
      min: 0.0000001,
      step: 0.00001,
      pad: 5,
      label: "Temperature",
    },
    shade: { value: 0.3, step: 0.01, min: 0.0, max: 1, label: "Shade" },
    shadeColor: { value: "#000000", label: "Shade Color" },
  },
  settings: { collapsed: true },
};
export const RGB_DEFAULT = {
  alphaChannel: {
    value: null,
    options: ["alpha", null],
  },
  rotation: {
    value: { x: 0, y: 0, z: 0 },
    order: 1,
    lock: true,
  },
  position: {
    value: { x: 0, y: 0, z: 0 },
    order: -1,
    lock: true,
  },
  scale: {
    value: 1,
    min: 0.0001,
    step: 0.01,
    label: "Scale",
    order: 2,
    pad: 4,
  },
  // useAlpha: { value: false, label: "Use Alpha", order: 4 },
  use_aabb: { value: false, label: "Use AABB", order: 5 },
  aabb_min: {
    value: { x: -1, y: -1, z: -1 },
    label: "Min",
    step: 0.001,
    pad: 4,
    lock: true,
    order: 6,
  },
  aabb_max: {
    value: { x: 1, y: 1, z: 1 },
    label: "Max",
    step: 0.001,
    pad: 4,
    lock: true,
    order: 7,
  },
  alphaThreshold: {
    value: 0.0,
    min: 0,
    max: 1,
    step: 0.001,
    pad: 4,
    label: "ɑ Threshold",
  },
};
