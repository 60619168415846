import React, { useMemo } from "react";
import { half2float } from "./_half2float";

export function TriMesh({
  tag,
  id,
  src,
  buff,
  hide,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  vertices,
  faces,
  colors,
  color,
  materialType = "standard", // One of ["basic", "standard", "phong", "standard", "lambert"]
  wireframe,
  opacity,
  side = "front", //OneOf["front", "back", "double"]
  ...rest
}) {
  const geometry = useMemo(() => {
    const byteRatio = Uint8Array.BYTES_PER_ELEMENT / Float32Array.BYTES_PER_ELEMENT;
    return {
      vertices: half2float(vertices),
      faces: new Uint32Array(faces.buffer.slice(faces.byteOffset), 0, byteRatio * faces.byteLength ),
      colors: colors && Float32Array.from(colors, (octet) => octet / 0xff),
    };
  }, [vertices, faces, colors]);

  const MType =
    "mesh" +
    materialType.charAt(0).toUpperCase() +
    materialType.slice(1) +
    "Material";

  return (
    <mesh
      position={position}
      rotation={rotation}
      castShadow
      receiveShadow
      {...rest}
    >
      <bufferGeometry onUpdate={(self) => self.computeVertexNormals()}>
        <bufferAttribute
          attach="attributes-position"
          array={geometry.vertices}
          count={geometry.vertices.length / 3}
          itemSize={3}
        />
        {colors && (
          <bufferAttribute
            attach="attributes-color"
            array={geometry.colors}
            count={geometry.colors.length / 3}
            itemSize={3}
          />
        )}
        <bufferAttribute
          attach="index"
          array={geometry.faces}
          count={geometry.faces.length}
          itemSize={1}
        />
      </bufferGeometry>
      <MType
        wireframe={wireframe}
        attach="material"
        //only use vertex colors if it is provided.
        vertexColors={!!colors}
        color={color}
        transparent={true}
        alphaTest={1}
        opacity={opacity}
        // one of [0, 1, 2]
        side={2}
      />
    </mesh>
  );
}
