export function findByKey(node, key, extra = []) {
  if (node.key === key) return node;

  let all = ["children", ...extra];

  for (const attr of all) {
    if (!!node[attr]) {
      for (const child of node[attr]) {
        const result = findByKey(child, key, extra);
        if (!!result) return result;
      }
    }
  }
}

export function imageToBase64(img) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(img);
  });
}

export function jpg64ToUri(base64, mtype = "image/jpeg") {
  return `data:${mtype};base64,${base64}`;
}
