import React, { useEffect, useMemo, useRef } from "react";
import {
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  useFBO,
} from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { Matrix4, Vector3 } from "three";

export function CameraView({
  _ref,
  hide = null,
  width = 640,
  height = 480,
  // the world matrix of the camera
  matrix = null,
  // used for perspective camera
  fov = 60,
  // used for orthographic camera
  top = 1,
  bottom = -1,
  left = -1,
  right = 1,
  // used for positioning of the view
  origin = "bottom-left", // "bottom-left" | "top-left" | "bottom-right" | "top-right"
  distanceToCamera = 0.5,
  ctype = "perspective",
  ...rest
}) {
  const cameraRef = useRef(null);
  const planeRef = useRef();

  const { scene, size, camera } = useThree();

  const dpr = window.devicePixelRatio;
  const fbo = useFBO(width * dpr, height * dpr);

  const m = useMemo(() => new Matrix4(), []);
  const offset = useMemo(() => new Vector3(), []);

  useFrame(({ gl }) => {
    if (!cameraRef.current) return;

    gl.setRenderTarget(fbo);
    gl.render(scene, cameraRef.current);
    gl.setRenderTarget(null);

    if (!planeRef.current) return;
    const plane = planeRef.current;

    const aspect = width / height;
    const vAspect = size.width / size.height;
    const dirVec = new Vector3(0, 0, -1).applyEuler(camera.rotation);
    // prettier-ignore
    let polarity;
    switch (origin) {
      case "bottom-left":
        polarity = [-1, -1];
        break;
      case "bottom-right":
        polarity = [1, -1];
        break;
      case "top-left":
        polarity = [-1, 1];
        break;
      case "top-right":
        polarity = [1, 1];
        break;
    }
    // prettier-ignore
    const horizontal = Math.tan((camera.fov / 360) * Math.PI)
    offset
      .set(
        polarity[0] * (1 - width / size.width) * vAspect * horizontal,
        polarity[1] * (1 - height / size.height) * horizontal,
        0
      )
      .applyEuler(camera.rotation);

    const h = 2 * horizontal * distanceToCamera;
    const w = aspect * h;
    const scale = height / size.height;

    // plane.scale.set(0.001, 0.001, 1);
    plane.scale.set(w * scale, h * scale, 1);
    // do the pointing first to make align with image plane
    plane.position
      .copy(camera.position)
      .addScaledVector(dirVec, distanceToCamera);
    plane.lookAt(camera.position);
    plane.position.addScaledVector(offset, distanceToCamera);
  }, 1);

  useEffect(() => {
    if (!cameraRef.current) return;
    const cam = cameraRef.current;
    cam.aspect = width / height;
    cameraRef.current.updateProjectionMatrix();
  }, [width, height, cameraRef.current]);

  useEffect(() => {
    if (!cameraRef.current || !matrix || matrix.length !== 16) return;
    const cam = cameraRef.current;
    m.set(...matrix);
    m.decompose(cam.position, cam.quaternion, cam.scale);
    cam.rotation.setFromQuaternion(cam.quaternion);
    cam.updateProjectionMatrix();
  }, [matrix, cameraRef.current]);

  if (hide) return null;
  return (
    <>
      <Plane
        ref={planeRef}
        key="rgb" // ref={} // args={[1, 1]}
        args={[1, 1, width, height]}
        renderOrder={1}
      >
        <meshBasicMaterial attach="material" map={fbo.texture} reflection={0} />
      </Plane>
      {ctype === "perspective" ? (
        <PerspectiveCamera ref={cameraRef} fov={fov} {...rest} />
      ) : null}
      {ctype === "orthographic" ? (
        <OrthographicCamera
          ref={cameraRef}
          top={top}
          bottom={bottom}
          left={left}
          right={right}
          {...rest}
        />
      ) : null}
    </>
  );
}
