import uuid4 from "uuid4";

export class Store {
  constructor() {
    this.reducers = {};
    this.subscribers = {};
  }

  addReducer(eventType, reducer, id) {
    const uuid = id || uuid4();
    if (!this.reducers[eventType]) {
      this.reducers[eventType] = {};
    }
    this.reducers[eventType][uuid] = reducer;
    return () => {
      delete this.reducers[eventType][uuid];
    };
  }

  subscribe(eventType, handler, id) {
    const uuid = id || uuid4();
    if (!this.subscribers[eventType]) {
      this.subscribers[eventType] = {};
    }
    this.subscribers[eventType][uuid] = handler;
    return () => {
      delete this.subscribers[eventType][uuid];
    };
  }

  publish(event) {
    const eventType = event.etype;

    const reducers = this.reducers[eventType] || {};
    for (let id in reducers) {
      const reducer = this.reducers[eventType][id];
      event = reducer(event);
    }

    const subs = this.subscribers[eventType] || {};
    for (let id in subs) {
      const handler = this.subscribers[eventType][id];
      setTimeout(handler(event), 0);
    }

    const multicast = this.subscribers["*"] || {};
    for (let id in multicast) {
      const handler = multicast[id];
      setTimeout(handler(event), 0);
    }
    return event;
  }
}
