import React, { useCallback, useEffect, useMemo, useState } from "react";
import useWebSocket from "react-use-websocket";
import { document } from "browser-monads";
import { button, useControls } from "leva";
import useStateRef from "react-usestateref";
import { parse } from "query-string";
import { Store } from "../_nerf_components/_store";
import {unpack} from "msgpackr";

export const SocketContext = React.createContext("WebSocket");

export const WebSocketProvider = ({ children, onMessage: paramsOnMessage }) => {
  const [isConnected, setIsConnected, connectRef] = useStateRef(false);
  const [connectWS, setConnectWS] = useState(true);
  const [reconnect, allowReconnect] = useState(true);
  const [$, setReconnect, shouldReconnect] = useStateRef(true);

  const queries = useMemo(() => parse(document.location.search), []);
  const uplink = useMemo(() => new Store(), []);
  const downlink = useMemo(() => new Store(), []);

  const { socketURI } = useControls("Connection", {
    socketURI: {
      value: queries.ws || "ws://localhost:8080",
      order: 0,
      label: "Socket URI",
    },
    reconnect: button(
      (get) => {
        setConnectWS(false);
        allowReconnect(false);
        setTimeout(() => {
          setConnectWS(true);
        }, 100);
      },
      {
        disabled: !reconnect,
        label: isConnected ? "Reconnect" : "Disconnected",
        order: -10,
      }
    ),
  });

  const onMessage = useCallback(
    ({ data: message, isTrusted}) => {
      // const event = JSON.parse(message);
      if (!message?.arrayBuffer) return;
      message.arrayBuffer().then((darray)=>{
        const event = unpack(darray);
        if (typeof paramsOnMessage === "function") paramsOnMessage(event);
        downlink.publish(event);
      });
    },
    [socketURI]
  );

  function onOpen() {
    sendMsg({ etype: "INIT" });
    setIsConnected(true);
    allowReconnect(true);
  }

  function onClose() {
    setIsConnected(false);
    allowReconnect(false);
  }

  function onError() {
    setIsConnected(false);
    allowReconnect(false);
  }

  function onReconnectStop() {
    allowReconnect(true);
  }

  const { sendJsonMessage, readyState } = useWebSocket(
    socketURI,
    {
      onOpen,
      onError,
      onClose,
      onMessage,
      onReconnectStop,
      share: true,
      retryOnError: true,
      shouldReconnect() {
        return shouldReconnect.current;
      },
      reconnectAttempts: 3,
      reconnectInterval: 2000,
    },
    socketURI && connectWS
  );

  const sendMsg = useCallback(
    (event) => {
      if (!readyState) return;
      // race condition, avoid without testing.
      // if (!isConnected) return;
      // normalize the event object
      // if (!event?.value) event.value = {};
      // this is the middle ware.
      // catch all. Needed for INIT event handling
      if (event) sendJsonMessage(event);
    },
    [readyState]
  );

  useEffect(() => uplink.subscribe("*", sendMsg), [sendMsg, uplink]);

  return (
    <SocketContext.Provider value={{ sendMsg, uplink, downlink }}>
      {children}
    </SocketContext.Provider>
  );
};
