import {Interactive} from "@react-three/xr";
import {useFrame} from "@react-three/fiber";
import React from "react";
import * as THREE from "three";

export const SqueezeRayGrab = React.forwardRef((
    {onSqueezeStart, onSqueezeEnd, onSelect, children, bigChildren, ...rest},
    forwardedRef
) => {
  const grabbingController = React.useRef()
  const groupRef = React.useRef(null)
  const previousTransform = React.useMemo(() => new THREE.Matrix4(), [])
  React.useImperativeHandle(forwardedRef, () => groupRef.current)

  useFrame(() => {
    const controller = grabbingController.current
    const group = groupRef.current
    if (!controller) return

    group.applyMatrix4(previousTransform)
    group.applyMatrix4(controller.matrixWorld)
    group.updateMatrixWorld()

    previousTransform.copy(controller.matrixWorld).invert()
  })

  return (
      <group ref={groupRef}>
        {bigChildren}
        <Interactive
            onSqueezeStart={(e) => {
              grabbingController.current = e.target.controller
              previousTransform.copy(e.target.controller.matrixWorld).invert()
              onSqueezeStart?.(e)
            }}
            onSqueezeEnd={(e) => {
              if (e.target.controller === grabbingController.current) {
                grabbingController.current = undefined
              }
              onSqueezeEnd?.(e)
            }}
            {...rest}
            onSelect={(e) => {
              onSelect?.(e)
            }}
        >
          {children}
        </Interactive>
      </group>
  )
})