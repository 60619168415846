import React, {useContext, useEffect, useMemo} from "react";
import { useControls } from "leva";
import { SocketContext } from "../_contexts/_websocket";
import {parse} from "query-string";

export const Color = ({ levaPrefix = "Scene", color = null, ..._ }) => {
  const queries = useMemo(() => parse(document.location.search));
  const bgColor = useMemo(()=>{
    if (queries.background) return `#${queries.background}`;
    if (color) return color;
    return "#151822"
  }, [color])
  const { background } = useControls(
    levaPrefix,
    {
      background: {
        value: bgColor,
        label: "Background Color",
      },
    },
    { collapsed: true }
  );
  const { sendMsg, uplink } = useContext(SocketContext);
  useEffect(
    () =>
      uplink.addReducer("CAMERA_MOVE", (event) => {
        // console.log("CAMERA_MOVE-reducer-once", event);
        return {
          ...event,
          value: {
            ...event.value,
            world: { ...event.value?.world, background },
          },
        };
      }),
    [uplink, background]
  );

  return <color attach="background" args={[background]} />;
};
