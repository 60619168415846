import React, { useEffect, useRef } from "react";
import { Vector3 } from "three";
import { invalidate, useThree } from "@react-three/fiber";
import { Sphere as ThreeSphere } from "@react-three/drei";
import { useControls } from "leva";

function getPosition(
  camera,
  raycaster,
  pointer,
  children,
  averageFirst = null
) {
  raycaster.setFromCamera(pointer, camera);
  const intersections = raycaster.intersectObjects(children, true);
  if (averageFirst) {
    const head = intersections.slice(0, averageFirst);
    const pos = head.reduce(
      (pos, { point }) => pos.add(point),
      new Vector3(0, 0, 0)
    );
    return pos?.divideScalar(head.length);
  } else {
    if (!!intersections) return intersections[0].point;
  }
}

function flattenGroups(children) {
  return children.reduce((acc, child) => {
    if (child.type === "Group") {
      return acc.concat(flattenGroups(child.children));
    } else {
      return acc.concat(child);
    }
  }, []);
}

export function Pointer({
  parent,
  thres,
  color = "red",
  onClick = null,
  childSelector = ({ type }) => type === "Points",
  average = null,
  disable = null,
}) {
  const { scene, mouse, camera, raycaster, pointer } = useThree();
  const ref = useRef();

  useEffect(() => {
    raycaster.params.Points.threshold = thres;

    const state = { is_down: false };

    const downHandle = (e) => {
      state.is_down = true;
    };
    const upHandle = (e) => {
      state.is_down = false;
    };
    const moveHandle = (e) => {
      /* do not cast when moving the mouse, for better performance */
      if (state.is_down) return;
      const children = flattenGroups(scene.children).filter(childSelector);
      const pos = getPosition(camera, raycaster, pointer, children, average);
      state.pos = pos;
      ref.current?.position.set(...pos);
      /* restart the render loop in on-demand mode */
      invalidate();
    };

    // multiple old handlers are not removed.
    const clickHandle = (e) => {
      // need to clone the object, otherwise it changes.
      if (typeof onClick !== "function") return;
      onClick({
        position: ref.current?.position.clone(),
        radius: thres,
        x: e.offsetX / e.target.offsetWidth,
        y: e.offsetY / e.target.offsetHeight,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        offsetHeight: e.target.offsetHeight,
        offsetWidth: e.target.offsetWidth,
      });
    };

    parent.current?.addEventListener("mousedown", downHandle);
    parent.current?.addEventListener("mouseup", upHandle);
    parent.current?.addEventListener("mousemove", moveHandle);
    parent.current?.addEventListener("click", clickHandle);

    // remove the handler
    return () => {
      parent.current?.removeEventListener("mousedown", downHandle);
      parent.current?.removeEventListener("mouseup", upHandle);
      parent.current?.removeEventListener("mousemove", moveHandle);
      parent.current?.removeEventListener("click", clickHandle);
    };
  }, [thres, onClick, disable]);

  return (
    <ThreeSphere ref={ref} args={[thres]}>
      {/* basicMaterial does not require lighting */}
      <meshBasicMaterial color={color} opacity={1} transparent />
    </ThreeSphere>
  );
}

export function PointerControl({ parentKey, sendMsg, parent, children }) {
  const { enableMarker, markerSize, markerAvg, color } = useControls(
    "Scene.Pointer",
    {
      color: "red",
      enableMarker: { value: false, label: "Enable" },
      markerSize: {
        value: 10,
        min: 0.1,
        max: 100,
        step: 0.1,
        pad: 1,
        label: "Pointer Size",
      },
      markerAvg: { value: 5, min: 0, max: 20, step: 1, label: "Average k" },
    },
    { collapsed: true }
  );

  const addMarker = ({ position, radius, x, y, ..._rest }) => {
    let event;

    // Skip if position (Vector3D) contains NaN
    if (
      !position ||
      position.x !== position.x ||
      position.y !== position.y ||
      position.z !== position.z
    ) {
      console.log("Clicked on free space, return as mouse click event.", _rest);
      event = {
        etype: "MOUSE_CLICK",
        key: parentKey,
        // note: change to clientX/Y, and use layer X/Y etc.
        value: { pointerX: x, pointerY: y, ..._rest },
      };
    } else {
      event = {
        etype: "ADD_MARKER",
        key: parentKey,
        value: { position, radius, pointerX: x, pointerY: y, ..._rest },
      };
    }
    if (typeof sendMsg === "function") sendMsg(event);
  };
  return (
    <Pointer
      parent={parent}
      thres={markerSize / 1000}
      onClick={addMarker}
      average={markerAvg}
      disable={!enableMarker}
      color={color}
    />
  );
}
