import React, { useRef } from "react";
import { useControls } from "leva";
import { Sphere, useHelper } from "@react-three/drei";
import {
  DirectionalLightHelper,
  PointLightHelper,
  SpotLightHelper,
} from "three";

export function DirectionalLight({
  _ref,
  _key,
  sendMsg,
  children,
  hide = false,
  intensity = 0.5,
  color = "white",
  levaPrefix = "Scene.",
  ...rest
}) {
  const lightRef = useRef();
  useHelper(lightRef, DirectionalLightHelper, 1, "red", [hide]);

  let controls = useControls(`${levaPrefix}Directional Light`, {
    intensity: { value: intensity, step: 0.005 },
    color,
    hide,
  });

  if (controls.hide) return null;
  return <directionalLight ref={lightRef} {...controls} {...rest} />;
}

export function AmbientLight({
  _ref,
  _key,
  sendMsg,
  children,
  hide = false,
  intensity = 0.5,
  color = "white",
  levaPrefix = "Scene.",
  ...rest
}) {
  let controls = useControls(
    `${levaPrefix}Ambient Light`,
    {
      intensity: { value: intensity, step: 0.005 },
      color,
      hide,
    },
    { collapsed: true }
  );
  console.log(controls);

  if (controls.hide) return null;
  return <ambientLight {...controls} {...rest} />;
}

export function SpotLight({
  _ref,
  _key,
  sendMsg,
  children,
  hide = false,
  intensity = 0.5,
  color = "white",
  levaPrefix = "Scene.",
  ...rest
}) {
  const lightRef = useRef();
  useHelper(lightRef, SpotLightHelper, 1, "red", [hide]);

  let controls = useControls(`${levaPrefix}Spot Light`, {
    intensity: { value: intensity, step: 0.005 },
    color,
    hide,
  });

  if (controls.hide) return null;
  return <spotLight ref={lightRef} {...controls} {...rest} />;
}

export function PointLight({
  _ref,
  _key,
  sendMsg,
  children,
  hide = false,
  intensity = 20,
  color = "white",

  levaPrefix = "Scene.",
  ...rest
}) {
  const lightRef = useRef();

  useHelper(lightRef, PointLightHelper, 1, "red", [hide]);

  let controls = useControls(`${levaPrefix}Point Light`, {
    intensity: { value: intensity, step: 0.005 },
    color,
    hide,
  });

  if (controls.hide) return null;
  return (
    <group>
      <pointLight ref={lightRef} {...controls} {...rest} />
      <Sphere
        args={[0.1, 32, 32]}
        position={rest.position}
        emissive={color || "white"}
        emissiveIntensity={intensity}
      />
    </group>
  );
}
