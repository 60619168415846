import React from "react";
import { create } from "zustand";

// export const SceneContext = React.createContext("Scene");
export const useSceneStore = create((set) => ({
  position: [0, 0, 0],
  rotattion: [0, 0, 0],
  scale: [1, 1, 1],
  update: (value) => set((state) => ({ ...state, ...value })),
}));
