import {shaderMaterial} from '@react-three/drei'
import {Vector2} from "three";


export const DepthMaterial = shaderMaterial(
  {uPose: [0, 0], uImage: null, uDepthMap: null, depthMul: 1.0},
  `
    varying vec2 vUv;
    void main() {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;
      gl_Position = projectionPosition;
      vUv = uv;
    }`,
  `
    precision mediump float;

    uniform vec2 uPose;
    uniform sampler2D uImage;
    uniform sampler2D uDepthMap;
    uniform float depthMul;

    varying vec2 vUv;

    void main() {
       vec4 depthDistortion = texture2D(uDepthMap, vUv);
       // normalized device coordinates
       float parallaxMult = depthDistortion.r * 2.0 * depthMul;

       vec2 parallax = tan(uPose * parallaxMult) - tan(uPose * depthMul);

       vec4 original = texture2D(uImage, (vUv + parallax));
       gl_FragColor = original;
    }`,
)
