import * as THREE from "three";
import { shaderMaterial } from "@react-three/drei";

export const PerspectivesDepthMaterial = shaderMaterial(
  {
    aspect: 1.33,
    pose: new THREE.Vector2(0, 0),
    image: null,
    depthMap: null,
    depthMul: 1.0,
    fov: 75,
    projectionMatrix: new THREE.Matrix4(),
    inverseProjectionMatrix: new THREE.Matrix4(),
    near: 0.1,
    far: 1000.0,
    ambientLightColor: new THREE.Color(0x404040),
    directionalLightColor: new THREE.Color(0xffffff),
    directionalLightDirection: new THREE.Vector3(0, 0, 1),
  },
  `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    void main() {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;
      gl_Position = projectionPosition;

      vUv = uv;
      vNormal = normalize(normalMatrix * normal);
      vViewPosition = -viewPosition.xyz;
    }`,
  `
    precision mediump float;

    uniform float aspect;
    uniform vec2 pose;
    uniform sampler2D image;
    uniform sampler2D depthMap;
    uniform float depthMul;
    uniform float fov;
    uniform mat4 projectionMatrix;
    uniform mat4 inverseProjectionMatrix;
    uniform float near;
    uniform float far;
    uniform vec3 ambientLightColor;
    uniform vec3 directionalLightColor;
    uniform vec3 directionalLightDirection;

    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    float ndcDepthToDistance(float ndcDepth) {
      float clipSpaceDepth = ndcDepth * 2.0 - 1.0;
      vec4 viewSpacePosition = inverseProjectionMatrix * vec4(0.0, 0.0, clipSpaceDepth, 1.0);
      float viewSpaceDepth = -viewSpacePosition.z / viewSpacePosition.w;

      return viewSpaceDepth;
    }

    void main() {
      vec4 depthDistortion = texture2D(depthMap, vUv);
      float ndcDepth = depthDistortion.r;

      float trueDistance = ndcDepthToDistance(ndcDepth);

      float aspectRatio = gl_FragCoord.w / gl_FragCoord.z;
      vec2 parallaxScale = vec2(tan(fov * 0.5) * trueDistance, 
                           tan(fov * 0.5) * trueDistance * aspectRatio);

      vec2 parallax = (pose) * depthMul * parallaxScale;

      vec4 original = texture2D(image, (vUv + parallax));

      vec3 ambientLight = ambientLightColor;
      vec3 lightDirection = normalize(directionalLightDirection);
      float diffuseFactor = max(dot(vNormal, lightDirection), 0.0);
      vec3 directionalLight = directionalLightColor * diffuseFactor;

      gl_FragColor.rgb = original.rgb * (ambientLight + directionalLight);
      gl_FragColor.a = original.a;
    }`,
);