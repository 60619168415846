export const _colormaps = [
  // "PCA",
  "Accent",
  "Blues",
  "BrBG",
  "BuGn",
  "BuPu",
  "CMRmap",
  "Dark2",
  "Dark2_r",
  "GnBu",
  "Greens",
  "Greys",
  "OrRd",
  "Oranges",
  "PRGn",
  "Paired",
  "Pastel1",
  "Pastel1_r",
  "Pastel2",
  "Pastel2_r",
  "PiYG",
  "PuBu",
  "PuBuGn",
  "PuOr",
  "PuRd",
  "Purples",
  "RdBu",
  "RdGy",
  "RdPu",
  "RdYlBu",
  "RdYlGn",
  "Reds",
  "Set1",
  "Set1_r",
  "Set2",
  "Set2_r",
  "Set3",
  "Set3_r",
  "Spectral",
  "Wistia",
  "YlGn",
  "YlGnBu",
  "YlOrBr",
  "YlOrRd",
  "afmhot",
  "autumn",
  "binary",
  "bone",
  "brg",
  "bwr",
  "cividis",
  "cool",
  "coolwarm",
  "copper",
  "cubehelix",
  "flag",
  "gist_earth",
  "gist_gray",
  "gist_heat",
  "gist_ncar",
  "gist_rainbow",
  "gist_stern",
  "gist_yarg",
  "gnuplot",
  "gnuplot2",
  "gnuplot2_r",
  "gray",
  "hot",
  "hsv",
  "inferno",
  "jet",
  "magma",
  "nipy_spectral",
  "ocean",
  "pink",
  "plasma",
  "prism",
  "rainbow",
  "seismic",
  "spring",
  "summer",
  "tab10",
  "tab10_r",
  "tab20",
  "tab20_r",
  "tab20b",
  "tab20b_r",
  "tab20c",
  "tab20c_r",
  "terrain",
  "turbo",
  "twilight",
  "twilight_shifted",
  "viridis",
  "winter",
];
