import React, { useEffect, useMemo, useRef } from "react";

export function AutoScroll({ sendMsg, _key: key, children, ...props }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = useMemo(() => () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0.016);
  });

  useEffect(() => {
    scrollToBottom();
  }, [children]);

  return (
    <div key={key} {...props}>
      {children}
      <div ref={messagesEndRef} />
    </div>
  );
}
