import React, {Suspense, useEffect, useState} from "react";
import { TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";
import { Outlines } from "@react-three/drei";
import { useControls } from "leva";
import {HeightMaterial} from "./_height_map_materials";

export function Primitive({
  _ref,
  _key = "Box",
  geometry: Geometry,
  sendMsg,
  children,
  hide = null,
  args,
  materialType = "basic", // One of ["basic", "standard", "phong", "standard", "lambert"]
  material: { displacementMap, normalMap, ..._material } = {},
  outlines,
  ...rest
}) {
  if (hide) return null;

  const [materialParams, setMaterial] = useState({});
  const [textures, setTexture] = useState({});
  const textureMaps = useLoader(TextureLoader, Object.values(textures) || []);

  useEffect(() => {
    // eslint-disable-next-line guard-for-in
    for (let k in _material) {
      const value = _material[k];
      const isMap = k.endsWith("map") || k.endsWith("Map");
      if (typeof value === "string" && isMap) {
        setTexture((store) => ({ ...store, [k]: value }));
      } else {
        setMaterial((store) => ({ ...store, [k]: value }));
      }
    }
  }, [rest]);

  const tMaps = Object.fromEntries(
    Object.keys(textures).map((key, i) => [key, textureMaps[i]])
  );

  return (
    <mesh ref={_ref} key={_key} {...rest}>
      <Geometry attach="geometry" args={args} />
      <Suspense>
        <HeightMaterial
          attach="material"
          type={materialType}
          _key={_key + "-material"}
          displacementMap={displacementMap}
          normalMap={normalMap}
          {...tMaps}
          {...materialParams}
        />
        {/*<Outlines angle={0} thickness={0.005} color="black" />*/}
        {outlines ? <Outlines {...outlines}/> : null}
      </Suspense>
    </mesh>
  );
}

export function Box(params) {
  return <Primitive geometry={"boxGeometry"} {...params} />;
}

export function Capsule(params) {
  return <Primitive geometry={"capsuleGeometry"} {...params} />;
}

export function Circle(params) {
  return <Primitive geometry={"circleGeometry"} {...params} />;
}

export function Cone(params) {
  return <Primitive geometry={"coneGeometry"} {...params} />;
}

export function Cylinder(params) {
  return <Primitive geometry={"cylinderGeometry"} {...params} />;
}

export function Dodecahedron(params) {
  return <Primitive geometry={"dodecahedronGeometry"} {...params} />;
}

export function Edges(params) {
  return <Primitive geometry={"edgesGeometry"} {...params} />;
}

export function Extrude(params) {
  return <Primitive geometry={"extrudeGeometry"} {...params} />;
}

export function Icosahedron(params) {
  return <Primitive geometry={"icosahedronGeometry"} {...params} />;
}

export function Lathe(params) {
  return <Primitive geometry={"latheGeometry"} {...params} />;
}

export function Octahedron(params) {
  return <Primitive geometry={"octahedronGeometry"} {...params} />;
}

export function Plane(params) {
  return <Primitive geometry={"planeGeometry"} {...params} />;
}

export function Polyhedron(params) {
  return <Primitive geometry={"polyhedronGeometry"} {...params} />;
}

export function Ring(params) {
  return <Primitive geometry={"ringGeometry"} {...params} />;
}

export function Shape(params) {
  return <Primitive geometry={"shapeGeometry"} {...params} />;
}

export function Sphere({
  _ref,
  _key,
  sendMsg,
  hide,
  args = [1],
  color = "white",
  materialType = "basic",
  ...params
}) {
  const controls = useControls(
    `Sphere-${_key}`,
    {
      radius: {
        value: args[0],
        step: 0.0001,
        optional: true,
        innerLabelTrim: 10,
        pad: 10,
      },
      color: { value: color, optional: true },
    },
    [...args]
  );
  return (
    <Primitive
      geometry={"sphereGeometry"}
      materialType={materialType}
      args={[controls.radius, ...args.slice(1)]}
      color={controls.color || color}
      {...params}
    />
  );
}

export function Tetrahedron(params) {
  return <Primitive geometry={"tetrahedronGeometry"} {...params} />;
}

export function Torus(params) {
  return <Primitive geometry={"torusGeometry"} {...params} />;
}

export function TorusKnot(params) {
  return <Primitive geometry={"torusKnotGeometry"} {...params} />;
}

export function Tube(params) {
  return <Primitive geometry={"tubeGeometry"} {...params} />;
}

export function Wireframe(params) {
  return <Primitive geometry={"wireframeGeometry"} {...params} />;
}